import * as React from "react";
import DentaLogo from "../../assets/img/logodenta.svg"
import styled from "styled-components";

function SvgComponent(props) {
  return (
    <ImgStyle src={DentaLogo} />
  );
}

export default SvgComponent;

const ImgStyle = styled.img`
  width: 60px;
  height: 60px;
  margin: 0px;
`;
