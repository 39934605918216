import React from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import Political from "./screens/Politics";
import Conditions from "./screens/Conditions";
import Soporte from "./screens/Soporte";
import { BrowserRouter as Router, Route , Routes} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      
      <Router>
          
          <Grid container>
   
              <Routes>
                <Route path='/' element={<Landing />}/>
                <Route path='/inicio' element={<Landing />}/>
                <Route path='/politicausuario' element={<Political />}/>
                <Route path='/politicaempresa' element={<Conditions />}/>
                <Route path='/soporte' element={<Soporte />}/>
              </Routes>
   
          </Grid>
       
      </Router>
    </>
  );
}

