import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";


import { SocialIcon } from 'react-social-icons';
import Grid from '@material-ui/core/Grid';
import Item from '@material-ui/core/Grid';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp} from "@fortawesome/free-brands-svg-icons";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "10px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Nuestros Servicios</h1>
           
          </HeaderInfo>
          <ServiceBoxRow className="flexCenter">
            <ServiceBoxWrapper>
              <ServiceBox
               
                title="Radiografías 2D HD"
                subtitle="En todas las sucursales.  Panorámica HD, Cefalométrica, Trazado Cefalométrico, Paquete Roth Willians, ATM, Periapicales Plus, Panorámica, Cefalométrica."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
               
                title=""
                subtitle=""
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
               
                title="Tomografía Dental 3D"
                subtitle="Sólo en Matriz. Tomografía Dental Completa, Tomografía Arco Superior, Tomografía Arco Inferior, Tomografía de zona, COnductometría 3D, Planificación de Implantes, Tomo Senos Paranasales, Paquete de Ortodoncia 3D."
              />
            </ServiceBoxWrapper>
            
           
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
         
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font20 semiBold">Guayaquil</h4>
                <h2 className="font40 extraBold">5 Sedes</h2>
                
                <p className="font16">
                
                 Matriz-Parqueo exclusivo.
                </p>
                <p className="font16">
                Kennedy-Parqueo Exclusivo.
                </p>
                <p className="font16">
                Alborada-Parqueo Exclusivo.
                </p>
                <p className="font16">
                Portete.
                </p>
                <p className="font16">
                  Almendros.
                </p>
                <br></br>
           <Grid container spacing={3}>
            <Grid item xs={4} sm={4} md={4}>
              <Item><SocialIcon url="https://facebook.com/drxdentaimagen?mibextid=ZbWKwL" /></Item>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Item><SocialIcon url="https://instagram.com/dentaimagen3d?igshid=YmMyMTA2M2Y=" /></Item>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <a href="https://wa.me/+593991381218?text=Necesito%20m%C3%A1s%20informaci%C3%B3n%20de%20los%20servicios%20que%20brindan.">
                <FontAwesomeIcon icon={faWhatsapp} size="3x" style={{color: "#25D366"}}  />
              </a>
            </Grid>
          </Grid>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;