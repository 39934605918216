import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";

import { SocialIcon } from 'react-social-icons';
import Grid from '@material-ui/core/Grid';
import Item from '@material-ui/core/Grid';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp} from "@fortawesome/free-brands-svg-icons";


export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
  
      
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">Centro Radiológico Digital.</h1>
          <HeaderP className="font13 semiBold">
            DentaImagen3D es un centro radiológico digital que brinda a sus clientes una imagen 3D dental.
          </HeaderP>
         
          <Grid container spacing={3}>
            <Grid item xs={4} sm={4} md={4}>
              <Item><SocialIcon url="https://facebook.com/drxdentaimagen?mibextid=ZbWKwL" /></Item>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Item><SocialIcon url="https://instagram.com/dentaimagen3d?igshid=YmMyMTA2M2Y=" /></Item>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <a href="https://wa.me/+593991381218?text=Necesito%20m%C3%A1s%20informaci%C3%B3n%20de%20los%20servicios%20que%20brindan.">
                <FontAwesomeIcon icon={faWhatsapp} size="3x" style={{color: "#25D366"}}  />
              </a>
            </Grid>
      
            
            
            
          </Grid>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} />
          
         
        </ImageWrapper>
       
      </RightSide>
    </Wrapper>
  );
}

const verde = styled.section`
   color : green;
`;

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


