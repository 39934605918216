import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Link as LinkEspecial } from 'react-router-dom';
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/Logo";


import { SocialIcon } from 'react-social-icons';
import Grid from '@material-ui/core/Grid';
import Item from '@material-ui/core/Grid';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp} from "@fortawesome/free-brands-svg-icons";


export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <LogoIcon />
          <h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>
          DentaImagen3D
          </h1>
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <LinkEspecial
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="/"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Inicio
          </LinkEspecial>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="services"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Servicios
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <LinkEspecial
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="/politicaempresa"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Políticas de la Empresa
          </LinkEspecial>
        </li>
        <li className="semiBold font15 pointer">
          <LinkEspecial
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="/politicausuario"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Políticas del Usuario
          </LinkEspecial>
        </li>
        <li className="semiBold font15 pointer">
          <LinkEspecial
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="/soporte"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Soporte DentiApp
          </LinkEspecial>
        </li>
             </UlStyle>
             <Grid container className="flexCenter">
            <Grid item xs={4} sm={4} md={4}>
              <Item><SocialIcon url="https://facebook.com/drxdentaimagen?mibextid=ZbWKwL" /></Item>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Item><SocialIcon url="https://instagram.com/dentaimagen3d?igshid=YmMyMTA2M2Y=" /></Item>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <a href="https://wa.me/+593991381218?text=Necesito%20m%C3%A1s%20informaci%C3%B3n%20de%20los%20servicios%20que%20brindan.">
                <FontAwesomeIcon icon={faWhatsapp} size="3x" style={{color: "#25D366"}}  />
              </a>
            </Grid>
          </Grid>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
